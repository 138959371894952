@import "variable.scss";
@media only screen and (max-width: 1023px) {
}
@media (min-width: 1241px) {
    .navbar {
        ul {
            li {
                &.logoutheader {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1461px){

    .header {
        min-height: 160px;
    }

}

@media (max-width: 1240px) {
    .buy_report_btn{
        text-align: start !important;
        margin: 10px 0px;
    }
    .main_container_row{
        display: flex;
    }
    .main_container_col{
        display: flex;
    }
    .filters_credit{
        padding-top: 10px;
    }
    .missing_tabs{
        display: initial;
    }
    .header_wrap,
    .header {
        min-height: 52px;
    }

    .header {
        background: #f8f8f8;
    }

    .humbergernav {
        display: inline-block;
        margin-left: 20px;
        padding: 4px 8px 3px;
        background: $primary;
        border-radius: 4px;
        button {
        background: $primary;
            color: #f8f8f8;
            border: none;
        }
    }

    .logo_wrap {
        margin-right: 0px;
    }

    .user_nav {
        padding-top: 10px;
        .hello,
        .dropdown {
            display: inline-block;
            display: none;
        }
    }

    .navbar {
        border: 0 !important;
        &.open {
            display: block;
            position: absolute;
            left: 0;
            top: 50px;
            background: #fff;
            border: 2px solid rgb(160, 160, 160);
            width: 100%;
            text-align: left !important;
            z-index: 1;
        }
        ul {
            li {
                list-style: none;
                display: block;
                background: $primary;
                clear: both;
                border-bottom: 1px solid $white;

                &.hello_login {
                    display: block;
                    font-family: "Roboto", sans-serif;
                    background: $header_color;
                    padding: 12px;
                    line-height: 21px;
                    font-size: 18px;
                    font-weight: 500;
                    color: $blue_grey;
                    &:hover {
                        background: $header_color;
                    }
                }
                &.logout {
                    font-family: "Roboto", sans-serif;
                    font-weight: 500;
                    background: $red;
                    color: $white;
                    // font-size: 13px;
                    // position: fixed;
                    // bottom: 0px;
                    // width: 100%;
                    a,
                    &:hover {
                        background: $red;
                        color: $white !important;
                        text-align: center;
                    }
                }

                &:hover {
                    background: $primary_mobile;
                }
                a {
                    padding: 13px 20px;
                }
            }
        }
    }

    .notopen {
        display: none;
    }

    .order_report_left {
        // display: none;
        position: relative;
        height: fit-content;
    }
    .report_steps{
        position: relative;

    }

    .select_report > div {
        width: 150px;
        height: 150px;
    }

    .select_report > div img {
        width: 50%;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .external_wrapper{
        padding: 10px 10px ;
    }
    .h_ar{
        margin-bottom: 10px;
    }
    .missing_tabs{
        display: initial;
    }
    .filters_credit{
        padding-top: 10px;
    }
}



@media only screen and (min-width: 1920px) {
      .sidebarNav_discussion{
          min-height: 78vh;
      }
      
      .Alert_bg{
           min-height: 75vh;
      }
    
      .sidebarNav_discussion_credit_post{
          min-height: 78vh;
      }
      .col_9_hight_different{
          min-height: 75vh;
      }
      .alertpagenation_text{
          top: 76vh;
      }
       .col_9_hight{
        min-height: 65vh;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1730px){

    .alertpagenation_text{
        top: 73vh;
    }

}

@media only screen and (min-width: 2048px){
    .sidebarNav_discussion{
        height: 80vh;
    }

    .alertpagenation_text{
        top: 77vh;
    }

}

@media only screen and (min-width: 2304px){
    .sidebarNav_discussion{
        height: 82vh;
    }

    .alertpagenation_text{
        top: 80vh;
    }

}

@media only screen and (min-width: 3072px){
    .sidebarNav_discussion{
        height: 86vh;
    }

    .alertpagenation_text{
        top: 84.5vh;
    }

}

@media only screen  and (max-width:1545px){
 
    .buy_report_btn{
        display: flex;
        flex-direction: column;

    }
    .text_start{
        text-align: start !important;
        margin-top: 10px;
    }
}
@media only screen  and (max-width:1076px){
    .search_res{
        margin-top: 10px;
        width: 100%;
        text-align: end;
    }
    .search_res_input{
        width: 100% !important;
    }
}

@media only screen  and (max-width:1275px){

.database_responsive{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.database_responsive_data{
    padding-top: 10px;
}
}